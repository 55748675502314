.certificate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem !important;
  gap: 1rem;
  color: white;
  font-family: "Poppins", "Nunito Sans", sans-serif;
}

.certificateHeading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.certificateImg {
  display: block !important;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  filter: blur(2px);
}

.certificatePreview {
  position: absolute !important;
  display: block;
  z-index: -1000;
  top: -9999;
  left: -9999;
}

@media (max-width: 1100px) {
  .certificateImg {
    max-width: 300px;
  }

  .certificatePreview {
    top: -9999 !important;
    left: -9999 !important;
    overflow: hidden !important;
  }
}

@media (max-width: 768px) {
  .certificate {
    padding: 1rem;
  }

  .certificateHeading {
    font-size: 1.5rem;
  }

  .certificateImg {
    max-width: 100%;
  }
}
