.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  color: white;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  border-radius: 8px;
  gap: 2rem;
  /* background-color: aliceblue; */
}

.progressWrapper {
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
  width: 100%;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
  color: #d49814;
}

.arIdText {
  font-size: 2rem;
  font-weight: 600;
  color: white;
}

.boxes {
  /* background-color: aliceblue; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.graphes {
  color: white;
  display: flex;
  flex-wrap: wrap;
  /* background-color: blue; */
  overflow-x: auto;
}
