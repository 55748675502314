.verifyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem;
  background-color: black;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 100vw !important;
  margin: auto;
  flex-wrap: wrap;
  gap: 1rem;
}

.info {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.rememberme {
  display: flex;
  align-items: center;
}

.rememberme .checkbox {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.resendButton {
  background-color: transparent;
  color: #007bff;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.resendButton:hover {
  text-decoration: underline;
}

.verifyButton {
  width: 100%;
}

@media (max-width: 768px) {
  .verifyContainer {
    padding: 0.25rem;
    margin: 0;
  }
}
