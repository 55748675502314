.feedbackMain {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  color: white;
}

.rating {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.score {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
}

.stars {
  display: flex;
  gap: 0.25rem;
}

.starFilled {
  color: #d49814;
  font-size: 1.5rem;
}

.starEmpty {
  font-size: 1.5rem;
}
.reviews {
  color: #888888;
  font-size: 1.2rem;
}

.feedbackContainer {
  margin-top: 3rem;
  display: flex !important;
  flex-direction: row;
}

.feedbackItem {
  width: 95% !important;
  max-width: 34rem;
  height: 100% !important;
  min-height: 32rem;
  max-height: 32rem;
  background-color: #4e4e4e;
  display: flex !important;
  flex-direction: column;
  gap: 1.5625rem;
  padding: 2rem;
  flex: 1 !important;
  border-radius: 1.875rem;
  box-sizing: border-box;
  align-self: stretch;
}

.feedbackItpWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 0.625rem;
  width: 100%;
  position: relative;
}

.feedbackIcon {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedbackTpWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.feedbackTitle {
  font-family: "Popins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
}

.feedbackPosition {
  font-family: "Popins", "Nunito Sans", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #a5a5a5;
}

.feedbackQuoteRight {
  position: absolute;
  font-size: 2.75rem;
  color: #d49814;
  right: 0.625rem;
  top: -0.3125rem;
}

.feedbackDescription {
  font-family: "Popins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #d8d8d8;
  max-width: 100%;
  overflow: hidden; /* Hides the overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis for overflow */
  display: -webkit-box; /* Required for multi-line ellipsis */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 9; /* Limits the content to 9 lines */
  line-clamp: 9; /* Standard property for compatibility */
}

@media (max-width: 768px) {
  .feedbackItem {
    width: 95% !important;
    height: 100% !important;
    min-height: 34rem;
  }

  .feedbackQuoteRight {
    font-size: 1.5rem;
    right: 0.1875rem;
    top: -0.1875rem;
  }
}
