.table {
  width: 100%;
  background: black;
  color: white;
  overflow: auto;
  text-align: left;
  flex-grow: 1;
  border-radius: 10px; /* Add border-radius property */
  overflow: hidden; /* Hide the overflow to avoid overlapping */
}

.tablehead {
  background-color: #313d4f;
  border-top-left-radius: 10px; /* Add border radius to top left */
  border-top-right-radius: 10px; /* Add border radius to top right */
}

.tablehead tr {
  padding: 1rem;
}

.tablehead th {
  padding: 1rem;
  color: aliceblue;
}

.tablebody {
  font-size: 16px;
  color: #fff;
  font-family: "Popins", "Nunito Sans", sans-serif;
  font-weight: 600;
}

.tablebody tr {
  padding: 1rem;
}

.tablebody td {
  border-bottom: 1px solid #3a3a3a;
  padding: 1rem;
}

.btns {
  display: flex !important;
  justify-content: space-between;
  gap: 1rem;
}

.btn {
  width: 100%;
}
