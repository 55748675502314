.home {
  height: 68vh;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 0.625rem;
  padding-bottom: 0 !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  overflow: hidden;
  /* animation: floatAnimation 6s ease-in-out infinite; */
  /* background-color: aqua; */
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.homeTextWrapper {
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 3rem;
  min-width: 40%;
  animation: floatAnimation 6s ease-in-out infinite;
}

.homeHeading {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  color: #d49814;
}

.homeHook {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 4rem;
  line-height: 4.5rem;
  color: white;
}

.homeHookHighlight {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.homeHookHighlight::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -0.1875rem;
  width: 0%;
  /* Start with 0% width to hide the underline */
  height: 1rem;
  /* Height to give the feel of a thick brush stroke */
  background: url("data:image/svg+xml,%3Csvg%20width%3D%22111%22%20height%3D%2212%22%20viewBox%3D%220%200%20111%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M2.35137%205.06247C20.2539%201.99745%2066.621%20-1.44742%20108.868%209.29324%22%20stroke%3D%22%23FFC27A%22%20stroke-width%3D%224%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(-50%);
  animation: underlineReveal 1s ease-out forwards;
  /* Trigger animation */
  animation-delay: 0.5s;
  /* Optional delay for better timing */
}

@keyframes underlineReveal {
  0% {
    width: 0%;
  }

  100% {
    width: 110%;
    /* Grow to full width */
  }
}

.homeTag {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #cfd3d6;
}

.homeBtn {
  width: 50%;
  font-size: 1.6rem !important;
}

/* section - other half  */
.homeImgSvgWrapper {
  /* background-color: yellow; */
  height: 68vh;
  display: flex;
  flex: 1;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.homeImgWrapper {
  position: absolute;
  top: 30%;
  left: 30%;
  transform: translate(-30%, -30%);
}

.homeImg {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  width: auto;
  height: 68vh;
  display: block;
  margin: 0 auto;
}

.homeTopCard {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 0.625rem;
  box-shadow: 0.543125rem 0.543125rem 0px 0px rgba(0, 0, 0, 0.15);
  border-width: 0.068125rem;
  border-radius: 0.625rem;
  border-color: #b2bbcc;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;
}

.homeLeftCard {
  box-shadow: 0.5rem 0.5rem 0px 0px rgba(0, 0, 0, 0.15);
  width: 15.25rem;
  height: 6.5rem;
  position: absolute;
  top: 50%;
  left: -0.1rem;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  padding: 0.5rem;
  border-width: 0.0625rem;
  border-radius: 0.625rem;
  border-color: #b2bbcc;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.homeLeftCardPercent {
  font-size: 1.25rem;
  padding-bottom: 0 !important;
  margin-bottom: -0.4375rem !important;
}

.homeLeftCardImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.homeLeftCardImageItem {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-left: -1.8rem;
  border: 0.18rem solid #f0f0f0;
  box-shadow: 0 0 0.625rem rgba(249, 249, 249, 0.1);
}

.homeLeftCardImageItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeLeftCardJackpotImg {
  width: 6.25rem;
  height: 6.25rem;
}

.homeLeftCardImageItem:first-child {
  margin-left: 0;
}

/* Media query for small devices */
@media (max-width: 768px) {
  .home {
    min-height: 87vh;
    height: auto;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 0ch !important;
    align-items: end;
  }

  .homeTextWrapper {
    margin-bottom: -1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem !important;
    /* background-color: aliceblue; */
   
  }

  .homeHook {
    font-weight: 600;
    font-size: 3rem;
    line-height: 4rem;
  }

  .homeImgSvgWrapper {
    /* background-color: lime; */
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    justify-content: flex-end; /* Pushes .homeImgWrapper to the bottom */
  }

  .homeImgWrapper {
    position: static;
    transform: none;
    /* background-color: yellow; */
  }

  .homeImg {
    margin-bottom: 0ch !important;
    padding-bottom: 0ch !important;
    max-width: 85vw;
    height: auto;
    /* background-color: yellow; */
  }

  .homeBtn {
    width: 100%;
  }
}
