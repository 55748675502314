.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f2f2fde;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayBlock {
  display: flex;
}

.displayNone {
  display: none;
}

.container {
  background-color: black;
  border: 1px solid #333;
  color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  display: flex !important;
  width: 100%;
  flex: 1;
  max-width: 500px;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.heading {
  font-size: 1.45rem;
}

.closeBtn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.closeBtn img {
  width: 20px;
  height: 20px;
}

.wrapperContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.labelText {
  color: #ddd;
  font-weight: 500;
}

.inputTextArea {
  width: auto;
  padding: 0.8rem;
  background-color: #000 !important; /* Ensure this remains black */
  border: 1px solid #555;
  border-radius: 4px;
  color: #ddd !important; /* Text color */
  font-size: 1rem;
  max-width: 100%;
  outline: none; /* Remove default blue outline on focus */
}

.inputTextArea:focus {
  background-color: #000; /* Keep the background black when focused */
  border-color: #888; /* Optional: Change border color for focus state */
  color: #ddd;
}

/* Style for placeholder text */
.inputTextArea::placeholder {
  color: #888; /* A lighter gray for visibility */
  font-style: italic; /* Optional: Make it italic for differentiation */
  opacity: 1; /* Ensure full opacity */
}

.stars {
  display: flex;
  gap: 0.5rem;
}

.star {
  cursor: pointer;
  transition: color 0.2s ease;
  width: 2rem;
  height: 2rem;
}

.star:hover,
.star.active {
  color: #ffd700;
}

@media (max-width: 768px) {
  .container {
    padding: 1.5rem;
  }

  .star {
    width: 1.5rem;
    height: 1.5rem;
  }
}
