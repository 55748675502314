

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2F2F2FDE;
    z-index: 1000;
  }
  
  .modal-main4 {
    position: fixed;
    background-color: rgb(0, 0, 0);
    width: 500px;
    top: 50%;
    height: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    height: auto;

  }

  .modal-main4 .closebtn{
      right:20px;
      position: fixed;
    cursor: pointer;

  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .modal-main4 .mainc{
      display: flex;
      flex-direction: column;
      padding: 1rem 2rem;
  }
  .modal-main4 .heading{
    color: rgb(0, 0, 0);
    text-align: center;
    width: 200px;
    font-size: 25px;
    font-weight: 500;
      font-family:'Poppins', 'Nunito Sans', sans-serif;



}

.modal-main4 .btnc{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.modal-main4 .btn{
    background: linear-gradient(91.94deg, #B17E32 -8.77%, #F3E681 58.63%, #CF9F41 107.45%);


    width: 300px;
    text-align: center;
    cursor: pointer;
    padding: 1rem;
    color: black;
    font-weight: 700;
      font-family:'Poppins', 'Nunito Sans', sans-serif;

    font-size: 15px;
    text-decoration: none;
    margin: 1rem 0;
    border-radius: 10px;

}

.addheader{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(91.94deg, #B17E32 -8.77%, #F3E681 58.63%, #CF9F41 107.45%);
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.addheader .heading{
    color: #000 !important;
    font-size: 24px;
    font-weight: 700;

}

.modal-main4 .mainc .label{
  margin: 5px;
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.inputinstalll{
  background-color: #000000;
  padding: 1.2rem .7rem 1.2rem .7rem;
  /* width: 230px; */
    font-family:'Poppins', 'Nunito Sans', sans-serif;

  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  color: #BCBCBC;
  border: 1px solid #3a3a3a;
  color: #fff;
  font-size: 16px;
  margin: 10px 0;
  color-scheme: dark;
}

.imgcontainer{
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.selected-img{
  max-width: 80px;
  max-height: 80px;
}
.delete-icon{
  height: 30px;
  width: 30px;
}


.image-wrapper{
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}