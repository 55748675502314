.progress {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
  max-width: 100%;
}

.courseSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.heading {
  font-size: 20px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.dialogs {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 1rem;
}

.dialog {
  height: auto;
  width: 100%;
  max-width: 284px;
  background-color: #060606;
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 1rem;
  border: 0.6px solid rgb(66, 66, 66);
}

.subHeading {
  font-size: 16px;
  color: #d49814;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.text {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
  .dialogs {
    flex-direction: column;
    align-items: center;
  }

  .dialog {
    width: 90%;
    margin: 10px auto;
  }
}
