.inputbox {
  display: flex;
  justify-content: space-between;
}

.inputbox input {
  padding: 1rem 1.2rem;
  background-color: #000000;
  border: 1px solid #ffffff4d;
  border-radius: 5px;
  color: #8190bf;
  outline: none;
  font-size: 20px;
  width: 25px;
  height: 25px;
  max-width: 30px !important;
  max-height: 30px !important;
  text-align: center;
  margin-right: 10px;
}

.inputbox input::placeholder {
  color: #8190bf;
}

@media (max-width: 768px) {
  .inputbox input {
    padding: 1rem;
    margin-right: 5px !important;
    font-size: 16px;
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
    margin-right: 1px;
  }
}
