/* LoginInput.module.css */

.loginContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: aliceblue; */
}

.phoneInputWrapper {
  /* background-color: blue; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-bottom: 0.5rem;
}
