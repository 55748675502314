.piechart{
    width: 20%;
    min-width: 350px;
    height: 280px;
    background-color: #000;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

#myChart{
    height: 120px !important;
    width: 120px !important;
    margin-bottom: 1rem;
}

.split{
    display: flex;
    justify-content: space-around;
    background-color: #1B254B;
    margin: 0 2rem;
    border-radius: 20px;
    height: 45px;
    align-items: center;
    padding: 14px 0;
}

.hedar{
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    gap: 2rem;
}

.hedar .subhed{
    font-weight: 500;
      font-family:'Poppins', 'Nunito Sans', sans-serif;

    font-size: 16px;

}

.piecontainer{
    display: flex;
    justify-content: center;
}

.spleft .he{
    margin: 0;
    font-size: 12px;
    font-weight: 500;
      font-family:'Poppins', 'Nunito Sans', sans-serif;


    color: #A3AED0;
    display: flex;
    align-items: center;

}

.spleft .mainhe{
      font-family:'Poppins', 'Nunito Sans', sans-serif;

font-size: 17px;
    font-weight: 500;
}

.purdot{
    height: 10px;
    width: 10px;
    background-color: #7551FF;
    border-radius: 50%;
    margin-right: 3px;
}

.bludot{
    height: 10px;
    width: 10px;
    background-color: #39B8FF;
    border-radius: 50%;
    margin-right: 3px;
}