.signin {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("./banner.png") center center no-repeat;
  background-size: cover;
  height: 100vh;
}

.container {
  display: flex;
  background: #000;
  background-color: #000;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  overflow-y: auto;
  max-width: 700px;
  min-width: 36%;
  width: auto;
  height: auto;
  max-height: max-content;
  position: relative;
  padding: 2rem;
  color: white;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1rem;
  margin-left: 1rem;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  border-radius: 100%;
  cursor: pointer;
}

.icon:hover {
  background: linear-gradient(
    91.94deg,
    #f3e681 -8.77%,
    #b17e32 58.63%,
    #cf9f41 107.45%
  );
}

.iconImage {
  height: 1.25rem;
  width: 1.25rem;
}

.heading {
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.signin .naa {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 1rem;
  color: white;
}

.signin .naa span {
  color: #f39c12;
  cursor: pointer;
}

@media (max-width: 768px) {
  .signin {
    background: none;
    background-color: #000;
  }

  .container {
    max-width: 95vw !important;
    padding: 0%;
    max-height: max-content;
  }

  .icon {
    display: none !important;
  }
}
