.popupOverlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity to control the darkness */
  backdrop-filter: blur(4px); /* Apply the blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.landregister,
.landlogin {
  background: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  color: #fff;
  width: 100%;
  position: relative;
}

.popupClose {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 3rem;
  cursor: pointer;
  color: #fff;
}

.popupContent h2 {
  text-align: center;
  font-size: 30px;
}

.popupContent .landlog {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.popupContent .landlog .st,
.tml,
.tra {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.popupContent .landlog .st img,
.tml img,
.tra img {
  border: 1px solid #f3e681;
  border-radius: 50%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
