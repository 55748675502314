/* Attendance.module.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f2f2fde;
  color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalMain {
  background-color: rgb(0, 0, 0);
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 90vh;
  position: relative;
}

.closebtn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.displayBlock {
  display: flex;
}

.displayNone {
  display: none;
}

.mainc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins", "Nunito Sans", sans-serif;
}

.btnc {
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.btn {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding: 1rem;
  color: black;
  font-weight: 700;
  font-family: "Poppins", "Nunito Sans", sans-serif;

  font-size: 15px;
  margin: 1rem 0;
  text-wrap: nowrap;
}

.middlecontent {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.hrLine {
  width: 100%;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.wrapperItem {
  color: white;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
