.table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  background-color: black;
  color: white;
  border-radius: 8px;
  overflow: hidden;
}

.tablehead {
  background-color: #333;
  color: #fff;
  text-align: left;
  font-weight: 700;
}

.tablehead td {
  padding: 0.75rem 1rem;
}

.tablebody tr td {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #333;
}

.icons img {
  padding: 2px;
  height: 15px;
}

@media (max-width: 768px) {
  .tablebody tr td {
    font-size: 0.9rem;
    padding: 1rem 0.5rem;
  }
}
