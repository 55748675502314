.bigcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bigheading {
  font-size: 40px;
  color: #d49814;
  padding: 0 1rem;
}
.saletarget {
  padding: 1rem 2rem;
}

.saletarget .heading {
  padding: 0 1rem;
  color: #fff;
}

.saletarget .btn {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );

  width: 150px;
  text-align: center;
  cursor: pointer;
  padding: 1rem;
  color: black;
  font-weight: 700;
  font-family: "Poppins", "Nunito Sans", sans-serif;

  font-size: 17px;
  text-decoration: none;
  margin: 1rem 0;
}

.saletarget .tablebody tr td {
  padding: 0 10px;
}
.saletarget .tablebody th {
  text-align: center;
}
