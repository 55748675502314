.header {
  height: 12vh;
  position: sticky;
  top: 0ch;
  left: 0ch;
  right: 0ch;
  z-index: 1000 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #252524;
  padding: 0rem 9%;
  box-shadow: 0 -15px 30px rgba(0, 0, 0, 0.15);
}

.mobileView {
  display: none;
}

.desktopView {
  max-width: 70%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.menuBtn {
  display: none !important;
}

.headerLogo {
  margin-left: 1rem;
  font-family: "Popins", "Nunito Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.25rem;
  color: white;
  text-decoration: none;
  text-wrap: nowrap;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.navbar a {
  position: relative;
  margin: 0 1rem;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.1875rem;
  text-decoration: none;
  color: #fff;
  text-wrap: nowrap;
  transition: all 0.3s ease;
  background: none;
}

.navbar a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  width: 0%;
  height: 2px;
  background: linear-gradient(90deg, #cf9f41 0%, #f3e681 50%, #b17e32 100%);
  transition: width 0.4s ease;
}

.navbar a:hover {
  background: linear-gradient(90deg, #ffd700, #d49814);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translateY(-5px);
}

.navbar a:hover::after {
  width: 100%;
}

.navbar .activeLink {
  color: #d49814;
}

.headerBtns {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  align-items: center;
}

.btnLogin {
  cursor: pointer;
  background-color: #252524;
  width: 10.4375rem;
  height: 3rem;
  border: 0;
  border-radius: 6.25rem;
  font-family: "Poppins", "Nunito Sans";
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* 768 max width  */
@media (max-width: 768px) {
  .header {
    justify-content: flex-start;
    align-items: center;
  }
  .mobileViewActive {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #252524;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 1rem 2rem;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: clip-path 0.3s ease-in-out;
  }

  .desktopView {
    display: none !important;
  }

  .menuBtn {
    cursor: pointer;
    font-size: 3.5rem;
    display: block !important;
    margin-right: 1rem;
    margin-top: 1.25rem;
    color: #fff;
    transition: transform 0.2s ease-in-out;
  }

  .mobileView .navbar a {
    display: block;
    width: 100%;
    padding: 1rem 0;
    margin: 0;
    color: white;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .mobileView .navbar .activeLink {
    color: #d49814;
  }

  .headerBtns {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column !important;
    align-items: start;
    gap: 1.5rem;
  }

  .btnLogin {
    background: linear-gradient(
      91.94deg,
      #b17e32 -8.77%,
      #f3e681 58.63%,
      #cf9f41 107.45%
    ) !important;
    min-width: max-content;
    width: 100%;
    padding: 1.25rem 2rem;
    color: black !important;
    font-weight: 700 !important;
    border: none !important;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    height: 3.2rem !important;
    cursor: pointer;
    transition: all 0.5s ease !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .btnLogin:hover {
    background: linear-gradient(
      91.94deg,
      #f3e681 -8.77%,
      #b17e32 58.63%,
      #cf9f41 107.45%
    ) !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
}
