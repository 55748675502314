/* More specific selector to override react-d3-tree defaults */
g > .link__to-branch {
    stroke: goldenrod !important;
    stroke-width: 2px !important;
  }
  
  g > .link__to-leaf {
    stroke:gold !important;
    stroke-width: 2px !important;
  }
  .custom-node {
    background-color: #333 !important; /* Dark background */
    border: 2px solid gold !important; /* Golden border */
    color: gold !important; /* Golden text color */
    font-family: Arial, sans-serif !important;
    font-size: 16px !important;
    border-radius: 10px !important; /* Rounded corners */
    padding: 5px 10px !important;
    text-align: center !important;
    display: inline-block !important;
    width: auto !important; /* Adjust to fit the text */
    height: auto !important; /* Adjust to fit the text */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5) !important; /* Optional shadow */
  }
  