.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f2f2fde;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayBlock {
  display: flex;
}

.displayNone {
  display: none;
}

.modalMain {
  background-color: black;
  color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  padding: 2rem;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 85vh;
}

.closeBtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
}

.closeBtn img {
  width: 20px;
  height: 20px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}


.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}



.labelInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.labelText {
  color: #ddd;
  font-weight: 500;
}

.input {
  width: auto;
  padding: 0.8rem;
  background-color: #000;
  border: 1px solid #555;
  border-radius: 4px;
  color: #ddd;
  font-size: 1rem;
  max-width: 100%;
}



@media (max-width: 480px) {
  
}
