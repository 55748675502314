.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  background-color: #2f2f2fde;
  z-index: 1000;
  color: white;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.displayBlock {
  display: flex !important;
}

.displayNone {
  display: none !important;
}

.modalMain {
  border-radius: 1rem;
  background-color: black;
  flex: 1;
  max-width: 40vw !important;
  height: 100%;
  max-height: 90vh !important;
  padding: 1rem 1.5rem !important;
  display: flex !important;
  position: relative;
  overflow-y: auto;
}

.closebtn {
  right: 1rem;
  position: absolute;
  cursor: pointer;
}

.mainContent {
  display: flex !important;
  flex: 1 !important;
  gap: 1rem;
  height: 100%; /* Ensure it stretches fully within the parent */
  min-height: 0; /* Prevent flexbox issues with overflowing children */
  flex-direction: column;
  padding-bottom: 1rem;
}

.notifyWrapper {
  display: flex;
  flex-direction: column;
  background-color: #2f2f2fde;
  padding: 1rem 2rem;
  border-radius: 0.75rem;
}

@media (max-width: 768px) {
  .modal {
    background-color: black;
  }
  .modalMain {
    max-width: 100vw !important;
  }
}
