.mediaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.mediaParagraph {
  width: 80%;
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.mediaItems {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
}

.mediaItemWrapper {
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 13.625rem;
  height: 6.8125rem;
  cursor: pointer;
  /* background-color: #4e4e4e; */
}

.mediaImg {
  width: auto;
  max-width: 8rem !important;
  height: auto;
  max-height: 6rem;
  background-color: #4e4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mediaName {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.875rem;
  text-align: center;
  color: white;
  text-wrap: wrap;
  display: none; 
}

@media (max-width: 1000px) {
  .mediaItems {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 700px) {
  .mediaItems {
    grid-template-columns: repeat(2, 1fr);
  }

  .mediaName {
    display: none;
  }
}
