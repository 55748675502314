.Gift{
    padding: 1rem 2rem;
    
}
.Gift .heading{
    color: rgb(255, 255, 255);
}
.Gift .sl{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Gift .sl .btn{
    background: linear-gradient(68.42deg, #B17E32 -8.44%, #F3E681 54.36%, #CF9F41 99.85%);
    color: black;
    text-decoration: none;
    padding: .6rem 1.2rem;
    font-size: 17px;
    font-weight: 600;
}


.Gift .btns *{
    cursor: pointer;
    margin: 0 5px;
}