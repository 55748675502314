.RegisterBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("./banner.png") center center no-repeat;
  background-size: cover;
  height: 100vh;
}

.RegisterContainer {
  display: flex;
  align-items: stretch;
  background: #000;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  max-width: 900px;
  width: 100%;
  height: 85%;
  position: relative;
  padding: 1rem;
}

/* Left Image Styling */
.RegisterImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  background: #000;
}

/* Form Section Styling */
.formSection {
  width: 60%;
  padding: 2rem 1.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.headingText {
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 100%;
}

.labelInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.labelText {
  color: #ddd;
  font-weight: 500;
}

.labelInputWrapper input {
  width: auto;
  padding: 0.8rem;
  background-color: #000;
  border: 1px solid #555;
  border-radius: 4px;
  color: #ddd;
  font-size: 1rem;
  max-width: 100%;
}

/* currently this selectInput is in student & trainer register */
.selectInput {
  width: auto;
  padding: 0.8rem;
  background-color: #000;
  border: 1px solid #555;
  border-radius: 4px;
  color: #999;
  font-size: 1rem;
  max-width: 100%;
}

.labelInputWrapper input::placeholder {
  color: #999;
}

.formContainer sup {
  color: #e74c3c;
  font-size: 0.9em;
}

.alr {
  text-align: center;
  color: #eee;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.alr span {
  color: #f39c12;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .RegisterContainer {
    /* background-color: antiquewhite; */
    flex-direction: column !important;
    width: 95%;
    max-width: 100vw;
    height: auto;
    max-height: 100vh;
    padding: 2rem;
    overflow: hidden;
  }

  .RegisterImage {
    display: none !important;
  }

  .formSection {
    flex-wrap: wrap;
    stroke-width: none;
    width: auto;
    padding: 1rem !important;
  }

  .headingText {
    font-size: 1.8rem !important;
  }

  .labelInputWrapper input {
    padding: 0.6rem !important;
    font-size: 0.9rem !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .selectInput {
    padding: 0.6rem !important;
    font-size: 0.9rem !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .alr {
    font-size: 0.85rem !important;
  }
}

/* Extra Small Devices */
@media (max-width: 480px) {
  .headingText {
    font-size: 1.5rem !important;
  }

  .formSection {
    overflow-y: auto;
    scrollbar-width: none;
  }

  /* For WebKit browsers (Chrome, Safari) */
  .formSection::-webkit-scrollbar {
    display: none;
  }

  .labelInputWrapper input {
    justify-content: center !important;
    align-items: center !important;
    padding: 0.5rem !important;
    font-size: 0.8rem !important;
    width: 100% !important;
  }

  .selectInput {
    justify-content: center !important;
    align-items: center !important;
    padding: 0.5rem !important;
    font-size: 0.8rem !important;
    width: 100% !important;
  }
}
