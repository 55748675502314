.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  padding: 2rem;
  background-color: #1a1a1a;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: max-content;
}

.backButton {
  background-color: #3a3a3a;
  border: none;
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
  max-width: max-content;
}

.userProfile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #4a4a4a;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
}

.infoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  gap: 0.5rem;
}

.userLabel {
  font-size: large;
}

.userPara {
  color: rgb(158, 163, 168);
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }

  .userProfile {
    margin-bottom: 2rem;
  }

  .profileImage {
    width: 150px;
    height: 150px;
  }

  .infoWrapper {
    flex-direction: column;
    align-items: start !important;
  }
}
