.dashboard {
  background-color: black;
  display: flex;
  color: white;
  flex-direction: row;
  overflow-x: hidden !important; /* Remove overflow constraints to allow sticky to work */
  overflow-y: auto; /* Allow vertical scrolling */
  height: 100vh; /* Ensure full height */
}

.maincontent {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
}
