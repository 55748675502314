.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f2f2fde;
  z-index: 1000;
}

.modalMain {
  border-radius: 1rem;
  background-color: black;
  width: auto;
  min-width: 35%;
  max-width: 40vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: flex;
  position: relative;
}

.closebtn {
  right: 20px;
  position: absolute;
  cursor: pointer;
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

.mainc {
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.heading {
  width: auto;
  color: #fff;
  text-align: center;
  text-wrap: wrap;
  font-size: 25px;
  font-weight: 500;
  font-family: "Poppins", "Nunito Sans", sans-serif;
}

.btnc {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}

.btn {
  flex: 1;
}

@media (max-width: 768px) {
  .modalMain {
    max-width: 80vw;
  }
}
