.container {
    box-sizing: border-box;
    height: 20vh;
    background-color: black;
    color: black;
    font-family: 'Poppins', 'Nunito Sans', sans-serif;
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap;
    gap: 2rem;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.item {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.icon {
    background: linear-gradient(91.94deg, #B17E32 -8.77%, #F3E681 58.63%, #CF9F41 107.45%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    border-radius: 100%;
}

.img {
    height: 2rem;
    width: 2rem;
    color: black;
}

.titleDesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0.25rem;
}

.title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #d49814;
    text-align: start;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.description {
    font-size: 1.5rem;
    color: white;
    text-align: start;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/* Responsive styles for mobile screens */
@media only screen and (max-width: 768px) {
    .container {
        height: auto;
        padding: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        justify-items: center;
    }

    .item {
        width: 100%;
        max-width: 300px;
    }
}
