.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
}

.title {
  font-size: 8rem;
  font-weight: bold;
  color: #1a202c;
  margin-bottom: 1rem;
}

.message {
  font-size: 1.5rem;
  color: #4a5568;
  margin-bottom: 2rem;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  background-color: #4299e1;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #3182ce;
}

@media (max-width: 768px) {
  .title {
    font-size: 6rem;
  }

  .message {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 4rem;
  }

  .message {
    font-size: 1rem;
  }

  .button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}
