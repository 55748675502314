.dashboard {
    display: flex;
}
.dashboard .sidebar {
    position: sticky;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17%;
    background: rgba(0, 0, 0, 1);
    height: 100vh;
    color: #fff;
    overflow: hidden;
    transition: all 0.5s linear;
      font-family:'Poppins', 'Nunito Sans', sans-serif;

    text-wrap:nowrap;
    min-width: 300px;

}

.dashboard .sidebar .heading {
    padding: 1rem;
    color: #E4C748;
    font-family: 'Popins',"Nunito Sans", sans-serif;
    text-align: center;
    margin: 0 0 15px 0;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 56px;
}


.dashboard .sidebar .sidebarelement {
    font-size: 18px;
    padding: 1.3rem;
    cursor: pointer;

    display: flex;
}

.dashboard .sidebar .sidebarelement:hover{
    background-color: #110D03;
    font-size: 18px;
    cursor: pointer;
    padding: 1.3rem;
    display: flex;
    border-right: 3px solid #E4C748;
    color: #c0a448;

}

.dashboard .sidebar .sidebarelementactive {
    background-color: #110D03;
    font-size: 18px;
    cursor: pointer;
    padding: 1.3rem;
    display: flex;
    border-right: 5px solid #E4C748;
    color: #C19E29;
}

.dashboard .sidebar .sidebarelement span {
    padding: 0 2.3rem;
    display: flex;
    align-items: center;
}

.sidebarelementactive span {
    padding: 0 2.3rem;
    display: flex;
    align-items: center;

}

.dashboard .header{
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    padding: 1.2rem 3rem;
}
.dashboard .header .left{
    display: flex;
}
.dashboard .header .searchbar input{
    width: 420px;
    background-image: url('search.png');
  background-position: 10px 10px; 
  background-repeat: no-repeat;
}

.dashboard .header .right .icons{
    display: flex;
}

.dashboard .maincontent{
    width: 83%;
    overflow: hidden;
    z-index: 1;
}

.dashboard .header .menugrid{
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.dashboard .header .searchbar{
    display: flex;
    align-items: center;
}

.dashboard .header .searchbar input{
    background-color: #060606;
    padding: 0.7rem 1rem .7rem 2.5rem;
      font-family:'Poppins', 'Nunito Sans', sans-serif;

    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    border:0.6px solid #CFCFCF1D;
    color: #BCBCBC;
}
.dashboard .header .searchbar input::placeholder {
    color: #BCBCBC;
    font-size: 14px;
  }

  .dashboard .header .right .icons *{
      padding: 0 0.6rem ;
  }

.dashboard .content{
    background-color: #0a0a0a;
}
.sidebar {
    /* Your existing styles */
    transition: min-width 0.3s ease;
  }
  
  .expanded .sidebar {
    max-width: 4.5%; /* Set to the desired min-width when expanded */
    min-width:88px;
  }

  .expanded .maincontent {
    width: 95%; /* Set to the desired width when sidebar is expanded */
  }


  @media only screen and (max-width: 1200px) {
    .dashboard .header .searchbar input{
        width: 320px;
    }
 }

 @media only screen and (max-width: 1000px) {
    .dashboard .header .searchbar input{
        width: 220px;
    }
 }


 @media only screen and (max-width: 700px) {
    .dashboard .header .searchbar input{
        display: none;
    }
 }

