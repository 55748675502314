.Reviews {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2rem;
  align-items: start;
  padding: 0.5rem 2rem;
}


