.Prize {
  padding: 1rem 1rem;
}
.Prize .heading {
  color: white;
}

.Prize .textb .heading {
  color: #d49814;
  width: 400px;
}

.Prize .textb {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: -5rem;
  margin-left: -2rem;
}

.Prize .slotcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Prize .conmt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  border-radius: 20px;
  font-family: "Poppins", "Nunito Sans", sans-serif;
}

.conmt .heading {
  color: #d49814;
}

.conmt .top {
  border-bottom: 1px solid #3a3a3a;
}
.conmt .heading2 {
  color: #fff;
}

.red {
  color: red;
}
