.container {
  padding: 2rem;
  /* background-color: #2E2E2E; Optional, depending on your layout */
}

.paragraph {
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.sliderContainer {
  margin-top: 1.25rem;
}

.item {
  position: relative; /* This is important for positioning text inside */
  /* width: 95% !important; */
  max-width: 25rem;
  min-height: 24rem !important;
  height: 100%;
  box-sizing: border-box;
  border-radius: 1.5rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0.5rem; /* Reduce gap between slides by decreasing margin */
}

.img {
  width: 100%;
  height: auto;
  border-radius: 1.5rem;
  object-fit: cover;
  display: block;
}

.txtPos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  color: white;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  text-align: center;
}

.name {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: white;
  margin: 0.25rem 0;
}

.position {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #dcdcdc; /* A slightly lighter shade to differentiate from name */
  margin: 0;
}
