.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2F2F2FDE;
    z-index: 1000;
  }
  
  .modal-main {
    position: fixed;
    background-color: rgb(0, 0, 0);
    width: 300px;
    height: auto;
    top: 50%;
    height: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
  }

  .modal-main .closebtn{
      right:20px;
      position: fixed;
    cursor: pointer;

  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .modal-main .mainc{
      padding-top: 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
  }
  .modal-main .headinga{
    color: #fff;
    text-align: center;
    width: 300px;
    font-size: 35px;
    font-weight: 500;
      font-family:'Poppins', 'Nunito Sans', sans-serif;



}

.modal-main .btnc{
    display: flex;
    align-items: center;
    gap: 10px;
}

.modal-main .btn{
    background: linear-gradient(91.94deg, #B17E32 -8.77%, #F3E681 58.63%, #CF9F41 107.45%);


    width: 100px;
    text-align: center;
    cursor: pointer;
    padding: 1rem;
    color: black;
    font-weight: 700;
      font-family:'Poppins', 'Nunito Sans', sans-serif;

    font-size: 15px;
    text-decoration: none;
    margin: 1rem 0;
    border-radius: 10px;

}

.middlecontent{
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}