/* ContactUs.module.css */
.page {
  margin: 0ch !important;
  padding: 0ch !important;
  background-color: black;
  box-sizing: border-box;
}

.contactUsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  padding: 6rem 14rem;
  color: #d49814;
  gap: 5rem;
}

.contactFormSection {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background: #121212;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.heading {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #cf9f41;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: medium;
}

.labelInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.labelText {
  color: #ddd;
  font-weight: 500;
}

.labelInputWrapper input {
  width: auto;
  padding: 0.8rem;
  background-color: #000;
  border: 1px solid #555;
  border-radius: 4px;
  color: #ddd;
  max-width: 100%;
}

.inputTextArea {
  width: auto;
  padding: 0.8rem;
  background-color: #000 !important;
  border: 1px solid #555;
  border-radius: 4px;
  color: #ddd !important;
  max-width: 100%;
  outline: none;
}

.inputTextArea:focus {
  background-color: #000;
  border-color: #888;
  color: #ddd;
}

.inputTextArea::placeholder {
  color: #888;
  font-style: italic;
  opacity: 1;
}

.mapSection {
  display: flex;
  flex: 1;
  width: 100%;
}

.map {
  width: 100%;
  height: 100%;
  min-height: 420px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1068px) {
  .contactUsContainer {
    flex-direction: column;
    padding: 3rem 1rem;
  }
}
