
 .linegraph .lineheader{
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
}
 .linegraph .lefthed{
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: #1B254B;
    padding: 0 5px;
    border-radius: 10px;
}

.linegraph .lefthed *{
    
    padding: 0 5px;
}

 .linegraph .linecontent{
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    margin-top: -1rem;
}

.mainline *{
    height: 174px !important;
}


 .linegraph {
    width: 40%;
    min-width: 550px;
    
    background-color: #000;
    border-radius: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;

}

@media only screen and (max-width: 700px) {
     .linegraph .linecontent{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 2rem;
        margin-top: -1rem;
    }
   
 }

 @media only screen and (max-width: 900px) {
    .linegraph {
        width: 90%;
        min-width: 350px;
        background-color: #000;
        border-radius: 20px;
    }
    
 }

