.Inputbox {
    display: flex;
    justify-content: space-between;
}

.Inputbox input {
    width: 20px;
    height: 25px;
    margin-right: 10px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #FFFFFF4D;
    border-radius: 5px;
    background-color: #000000;
    color: #8190BF;
    outline: none;
}

.Inputbox input::placeholder {
    color: #8190BF;
}