.proofContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem; /* 15px */
}

.proofParagraph {
  width: 80%;
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.proofItems {
  /* background-color: aliceblue; */
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
}

.proofImg {
  width: auto;
  max-width: 90%;
  height: auto;
  min-height: 25rem;
  max-height: 45rem;
  background-color: #4e4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  object-fit: cover;
}
