.bo {
  display: flex;
  width: 100%;
  /* padding: 2rem 4rem; */
  background-color: #0d0f11;
  border-radius: 20px;
  border: 1px solid #0d0f11;
  /* margin-bottom: 2rem; */
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
}

.bo ::-webkit-scrollbar {
  height: 8px;
}

.bo ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.bo ::-webkit-scrollbar-thumb {
  background: #e4c748;
  border-radius: 10px;
}

.bo ::-webkit-scrollbar-thumb:hover {
  background: #e4c748;
}

.leader {
  color: #d49814;
  text-align: center;
}

.line {
  width: 98.02%;
  height: 2px;
  background: linear-gradient(
    68.42deg,
    #b17e32 -8.44%,
    #f3e681 54.36%,
    #cf9f41 99.85%
  );
}

.children {
  display: flex;
  justify-content: space-around;
  gap: 6rem;
}

.child {
  color: #d49814;
  background-color: #3e3e3e;
  border-radius: 10px;
  border: 2px solid #ebd672;
  padding: 1rem 2rem;
  font-weight: 600;
}

.vertical {
  border-left: 2px solid #f3e681;
  height: 36px;
}

.lineparent {
  display: flex;
  justify-content: space-around;
  gap: 6rem;
}

.timeline-section {
  width: max-content;
}

.timeline-container {
  overflow-x: auto;
  width: 100%;
}

.scrollable {
  padding-bottom: 3rem;
}

@media only screen and (max-width: 1000px) {
  .bo {
    padding: 2rem 0;
  }
}
