.packageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.packageParagraph {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.packageItems {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.packageItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  background-color: #414141;
  padding: 1rem;
  border-radius: 1rem;
}

.packageImg {
  width: 100%;
  max-width: 20rem;
  background-color: #4e4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem; /* 14.63px */
  padding: 0;
  overflow: hidden;
}

.packageTitle {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  text-wrap: wrap;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.packageDetails {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.packageDetails li {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.75rem; /* 16px */
  line-height: 2rem; /* 34px */
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-right: 10px;
}

.packageDots {
  display: block;
  height: 0.5rem; /* 8px */
  width: 0.5rem; /* 8px */
  border-radius: 50%;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
}

.packagePoints {
  flex-direction: column;
  list-style: disc;
}

.packagePoints li {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2;
  color: white;
}

.hrLine {
  border: 0.07625rem solid rgba(231, 233, 235, 0.3);
  width: 100%;
  height: 0;
}

.packagePriceWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.packagePrice {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
}

.packagePriceCross {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: #9b9898;
  margin-left: 0.5rem;
  text-decoration: line-through;
}

.packageBuy {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  border-radius: 50%;
  height: 2.5625rem; /* 41px */
  width: 2.5625rem; /* 41px */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
}

.packageBuy:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: linear-gradient(
    91.94deg,
    #f3e681 -8.77%,
    #b17e32 58.63%,
    #cf9f41 107.45%
  ) !important;
}

.packageBuyIcon {
  font-size: 1.5rem; /* 22px */
}

/* Responsive Design for small screens */
@media (max-width: 768px) {
  .packageParagraph {
    font-size: 1rem;
    line-height: 1.5;
  }
}
