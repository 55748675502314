.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.header {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
}

.courseImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.courseInfo {
  display: flex;
  flex: 1;
  padding: 0.2rem 0.1rem;
  flex-direction: column;
  justify-content: space-between;
}

.courseTitle {
  font-size: 18px;
  font-weight: bold;
  text-wrap: nowrap;
  overflow: hidden;
}

.coursePrice {
  font-size: 1rem;
}

.qrCodeSection {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.qrCodeImage {
  width: 150px;
  height: 150px;
}

.qrCodeText {
  font-size: 14px;
  max-width: max-content;
  text-align: center;
}

.uploadSection {
  display: flex;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.btn {
  flex: 1;
}
