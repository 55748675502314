.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f2f2fde;
  z-index: 1000;
}

.modalMain {
  border-radius: 1rem;
  background-color: black;
  max-width: 40vw;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  position: relative;
}

.closebtn {
  right: 20px;
  position: absolute;
  cursor: pointer;
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

.mainc {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.heading {
  width: auto;
  color: #fff;
  text-align: center;
  text-wrap: wrap;
  font-size: 25px;
  font-weight: 500;
  font-family: "Poppins", "Nunito Sans", sans-serif;
}

.imageUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profilePhoto {
  width: 100%;
  height: 100%;
  max-height: 40vh;
  object-fit: cover;
  margin-bottom: 2rem;
  border: 1px solid #b17e32;
  background-color: wheat;
  border-radius: 10px;
  cursor: pointer;
}

.btn {
  width: 100%;
}

@media (max-width: 768px) {
  .modalMain {
    max-width: 80vw;
  }
}
