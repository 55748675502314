/* RefundPolicy.module.css */

.refundContainer {
  padding: 2rem;
  background-color: #060606;
  color: white;
  font-size: 1.6rem;
  line-height: 1.8;
}

.heading {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #d49814;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.content p,
.content ul {
  margin-bottom: 1.5rem;
}

.content ul {
  padding-left: 2rem;
}

.content h2 {
  font-size: 2rem;
  margin-top: 2rem;
  color: #cf9f41;
}

.link {
  color: #d49814;
  text-decoration: underline;
}

.link:hover {
  color: #cf9f41;
}
