.faq {
  width: 80%;
  margin: 0 auto;
}

.faqParagraph {
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.faqContainer {
  margin-top: 2.125rem;
}

.faqItem {
  /* border: 1px solid #ddd; */
  border-radius: 0.25rem;
  /* 4px */
  margin-bottom: 0.6875rem;
  /* 11px */
  overflow: hidden;
}

/* faq question css */
.faqQuestion {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: #4e4e4e;
  padding: 1.25rem 3rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqQuestion:hover {
  color: #000000;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
}

.faqQuestion.highlight {
  color: #000000;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
}

/* faq icons css  */
.faqQuestionIcons {
  height: 2rem;
  /* 32px */
  width: 2rem;
  /* 32px */
  color: #d49814;
}

.faqQuestion:hover .faqQuestionIcons {
  color: #000000;
}

.faqQuestionIcons.highlight {
  color: #000000;
  height: 2.125rem;
  /* 34px */
  width: 2.125rem;
  /* 34px */
}

/* faq answer css */
.faqAnswer {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 1rem 3rem;
  display: none;
  color: #000000;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
}

.faqAnswer.show {
  display: block;
}
