.certificate {
  background-color: #060606;
  color: #ffffff;
  width: 950px;
  min-height: 650px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: "Times New Roman", serif;
  position: relative;
  border: 2px solid rgba(205, 240, 7, 0.1);
}

.certificateSidebar {
  position: absolute;
  top: 0ch;
  bottom: 0ch;
  left: 0ch;
  max-width: 25%;
}

.certificateImg {
  height: 100%;
}

.mainContent {
  box-sizing: border-box;
  padding: 1rem 2rem;
  max-width: 75%;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0ch;
  top: 0ch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem !important;
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.certificateLogo {
  height: 100%;
  max-height: 60;
  max-width: 80;
  width: auto;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 10px;
}

.title {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 4px;
}

.subtitle {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
}

.presentedWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.75rem;
}

.presentedText {
  font-size: 1rem;
  text-transform: uppercase;
}

.recipient {
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  font-style: italic;
}

.textWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.1rem;
}

.textTitle {
  color: white;
  line-height: 2;
  font-size: 1.25rem;
}

.text {
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.dateWrapper,
.signatureWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.date,
.signature {
  text-align: center;
}

.line {
  width: 200px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.label {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-align: center;
}
