.clubParagraph {
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.clubItemContainer {
  margin-top: 1.875rem;
}

.clubItem {
  width: 90% !important;
  height: 100% !important;
  min-height: 22rem;
  box-sizing: border-box;
}

.clubImg {
  /* Converted to CSS module */
  width: 100%;
  height: 100%;
  min-height: 22rem;
  /* Changed from 4px to 0.25rem */
}
