.table {
    width: 100%;
    background: black;
    color: white;
    overflow: auto;
    text-align: left ;
    flex-grow: 1;
    border-radius: 10px; /* Add border-radius property */
    overflow: hidden; /* Hide the overflow to avoid overlapping */
}

.tablehead {
    background-color: #313D4F;
    opacity: 90%;
    border-top-left-radius: 10px; /* Add border radius to top left */
    border-top-right-radius: 10px; /* Add border radius to top right */
}

.tablehead tr * {
    padding: 1.2rem;
}

.tablebody tr td {
    padding: 1rem;
}

.tablebody td {
    border-bottom: 1px solid #3a3a3a;
}


.tablebody{
    font-size: 16px;
    color: #fff;

    font-family: 'Popins',"Nunito Sans", sans-serif;
    font-weight: 600;

}
.tablebody td{
    opacity: 70%;
}

.tablecon .btn{
    background: linear-gradient(68.42deg, #B17E32 -8.44%, #F3E681 54.36%, #CF9F41 99.85%);
    color: black;
    text-decoration: none;
    padding: .6rem 1.2rem;
    text-wrap: nowrap;
    border-radius: 10px;



}
.btns{
    display: flex;
    justify-content: center;
}

.tablecon .btns{
    opacity: 100%;
    padding: .9rem 1rem;
    
}

.tablecon{
    overflow-x: auto;
    width: 100%;
}

.btns .icns{
    display: flex;
    justify-content: space-around;
}
.btns .icns *{
    padding:0 3px;
    cursor: pointer;
}

.payno{
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px !important;
    border-radius: 5px;
    font-family: 'Popins',"Nunito Sans", sans-serif;
font-size: 14px;
font-weight: 700;
margin-left: 1rem;
}

.center{
    text-align: center;
}