/* body - index.html file */
body {
  margin: 0ch;
  padding: 0ch;
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* root - index.html file */
#root {
  height: 100%;
  margin: 0ch;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
  box-sizing: border-box;
}

/* applying to all div - it is must */
div {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
}

html::-webkit-scrollbar-thumb {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    68.42deg,
    #b17e32 -8.44%,
    #f3e681 54.36%,
    #cf9f41 99.85%
  );
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    68.42deg,
    #b17e32 -8.44%,
    #f3e681 54.36%,
    #cf9f41 99.85%
  );
}
