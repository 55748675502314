.container {
    width: 80%;
    margin: 0.9375rem auto;
    display: flex;
    flex-direction: row;
    gap: 3.125rem;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    padding: 2rem 4rem;
}

.divider {
    width: 0.125rem;
    height: 15.0625rem;
    position: relative;
    margin: 0 1.5625rem;
}

.divider::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 100%);
    border-right: 0.125rem dotted;
    background-clip: padding-box;
}

.items:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.imglink {
    width: 5rem;
    height: 5rem;
    color: #d49814;
}

.name {
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    line-height: 2.0625rem;
    color: white;
}

.description {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.6;
    color: white;
}

@media only screen and (max-width: 768px) {
    .container {
        width: 100%;
        flex-direction: column;
        gap: 6rem;
    }

    .divider {
        display: none;
    }

    .items {
        gap: 1.5rem;
    }
}
