.logoWrapper{
  display: block;
}


.logoImg {
  display: block;
  width: 10rem;
  height: auto;
}

@media (max-width: 992px) {
  .logoImg {
    width: 55px;
    height: auto;
  }
}
