.edu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  color: white;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  border-radius: 8px;
  gap: 2rem;
  /* background-color: aliceblue; */
}

.progressCharts {
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
  width: 100%;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #d49814;
}

.boxes {
  /* background-color: aliceblue; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.courses {
  font-size: 2rem;
  font-weight: 600;
  color: #d49814;
}

@media (max-width: 768px) {
  .edu {
    padding: 1rem;
    gap: 1.5rem;
  }

  .heading {
    font-size: 1.5rem;
  }

  .courses {
    font-size: 1.5rem;
  }
}
