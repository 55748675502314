.bannerContainer {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.125rem;
}

.bannerParagraph {
  flex: 1;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 4.375rem;
  text-align: start;
}

.bannerBtn {
  position: relative;
  overflow: hidden; /* Ensures shimmer doesn't overflow */
  background-color: black;
  color: white;
  height: 60px;
  width: 16.5rem;
  cursor: pointer;
  border-radius: 3.125rem;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerBtn .shimmer {
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  transform: skewX(-20deg);
  pointer-events: none; /* So that the shimmer doesn't interfere with hover */
}

@media (max-width: 900px) {
  .bannerContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 3.125rem;
  }

  .bannerParagraph {
    font-size: 2.5rem;
  }
}
