/* Leaderboard Container */
.leaderboard-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem; /* Increase spacing for better layout */
  padding: 2rem;
  background: rgba(0, 0, 0, 0.7); /* Slight transparency for a modern look */
  border-radius: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Paragraph */
.leaderboard-paragraph {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: center;
  color: #ffffff;
}

/* Leaderboard Table */
.leaderboard {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0.625rem; /* 10px */
  font-family: "Poppins", "Nunito Sans", sans-serif;
  text-transform: uppercase;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  overflow: hidden;
}

/* Table Head */
.leaderboard thead {
  font-weight: 600;
  font-size: 1.5rem; /* Larger for emphasis */
  line-height: 3.125rem;
  text-align: center;
  color: #d49814;
  background: #3e3e3e;
}

/* Table Body */
.leaderboard tbody {
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 3.125rem;
  text-align: center;
  color: black;
}

/* Table Data Cell */
.leaderboard td {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  padding: 0.75rem;
  border-radius: 0.5rem;
  color: rgb(0, 0, 0);
}

/* Loader */
.leaderboard-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  color: #ffffff;
}

.spinner {
  width: 3rem;
  height: 3rem;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: #d49814;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Leaderboard Row Styles */
.leaderboard-row {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: black !important; /* Changed from #3E3E3E to black */
}

/* .leaderboard-row:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
} */

/* Responsive styles for mobile screens */
@media only screen and (max-width: 768px) {
  .leaderboard-container {
    width: 96%;
    padding: 1rem;
  }

  .leaderboard-paragraph {
    font-size: 1.1rem;
  }

  .leaderboard {
    font-size: 1rem;
  }
}
